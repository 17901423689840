<template>
  <div class="department-master-list">
    <v-divider></v-divider>
    <div class="content-area pa-8">
      <div class="text-h5 blue--text text--darken-4">部署マスタ</div>

      <v-row class="py-4">
        <v-col cols="4">
          <v-text-field
            v-model="filterText"
            append-icon="mdi-magnify"
            label="フィルタ"
            single-line
            hide-details
          ></v-text-field>      
        </v-col>
        <v-col cols="8" class="d-flex justify-end">
          <v-btn color="btAccent" dark depressed @click="editItem(null)">
            <v-icon class="pr-1">mdi-plus-circle-outline</v-icon>
            新規登録
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        class="data-table"
        :headers="headers"
        :items="items"
        :search="filterText"
        style="background-color: transparent"
        no-data-text="該当データ無し"
        no-results-text="該当データ無し"
        :footer-props="{
          itemsPerPageText: '',
          itemsPerPageAllText: '全件',
          pageText: '{2}件中、{0}-{1}'

        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon
            class="mr-2"
            @click="editItem(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </div>

    <department-edit-dialog v-model="editDialog" :departmentData="editTarget" :add="editType == 'add'" @ok="fetchDepartmentData()"></department-edit-dialog>

    <v-dialog v-model="infoDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-subtitle-2">部署を削除しますか？</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2 mb-3"
            text
            width="190"
            @click="infoDialog = !infoDialog"
            >キャンセル
          </v-btn>
          <v-btn
            color="btAccent"
            class="white--text mx-2 mb-3"
            width="190"
            depressed
            @click="deleteOk"
            >削除</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="errorInfoDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-subtitle-2">{{info}}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="errorInfoDialog = !errorInfoDialog">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {RepositoryFactory} from '../../api/RepositoryFactory'
import DepartmentEditDialog from '../../components/atomic/organisms/master/DepartmentEditDialog'

export default {
  components: {
    DepartmentEditDialog,
  },
  data() {
    return {
      headers: [
        { text: '部署ID', value: 'id' },
        { text: '部署名', value: 'departmentName' },
        { text: 'アクション', value: 'actions', sortable: false },
      ],
      items: [],
      filterText: '',
      editDialog: false,
      editTarget: null,
      editType: 'add',

      infoDialog: false,
      deleteTarget: null,

      errorInfoDialog: false,
      info: '',
    };
  },
  computed: {
    ...mapGetters(["departmentsList"]),
  },
  async created() {
    await this.fetchDepartmentData();
  },
  methods: {
    ...mapActions([
      "getDepartments",
    ]),
    async fetchDepartmentData() {
      await this.getDepartments();
      this.items = this.departmentsList;
    },

    editItem(item) {
      this.editType = item ? 'edit' : 'add';
      this.editTarget = item;
      this.editDialog = true;
    },
    deleteItem(item) {
      this.infoDialog = true;
      this.deleteTarget = item;
    },

    async deleteOk() {
      if(this.deleteTarget) {
        const DepartmentRepository = RepositoryFactory.get( 'departments' );
        const response = await DepartmentRepository.delete( this.deleteTarget.id );
        this.infoDialog = false;

        if(response.data.errorCode == 'MSGCO-BTAPI-0201') {
          this.info = '所属するユーザーがいる部署は、削除できません';
          this.errorInfoDialog = true;
        }

        await this.fetchDepartmentData();
      }
    },

  },
  watch: {
    editDialog(val) {
      if(!val) {
        this.editTarget = null;
      } 
    },
  },

}
</script>
<style lang="scss" scoped>
.department-master-list {
  background-color: #F2F8FF;

  .content-area {
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;

    .data-table {
      & ::v-deep tbody {
        background-color: white;
      }
    }
  }
}
</style>

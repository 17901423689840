<template>
  <v-dialog 
    v-model="open"
    max-width="1000"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark flat color="#0761B4">
        <v-btn
          icon
          dark
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ formTitle }}</v-toolbar-title>
      </v-toolbar>
      
      <v-card-text class="pa-3">
        <v-container>
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="item.departmentName"
                label="部署名"
                :rules="[rules.required]"
                outlined
                clearable
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          class="mx-2 mb-3"
          text
          width="190"
          @click="close"
          >キャンセル
        </v-btn>
        <v-btn
          color="btAccent"
          class="white--text mx-2 mb-3"
          width="190"
          depressed
          @click="save"
          >{{buttonTitle}}</v-btn
        >
      </v-card-actions>

      <v-dialog v-model="infoDialog" max-width="500px">
        <v-card>
          <v-card-title class="text-subtitle-2">{{info}}</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="infoDialog = !infoDialog">OK</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <v-overlay
        :absolute="true"
        :value="overlay"
      >
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </v-overlay>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from "vuex";
import {RepositoryFactory} from '../../../../api/RepositoryFactory'
import * as _ from 'lodash';

export default {
  components: {
  },
  props: ["value", "departmentData", "add"],
  data() {
    return {
      item: {
        id: 0, 
        departmentName: null, 
      },


      formTitle: "部署編集",
      buttonTitle: "保存",

      infoDialog: false,
      info: '',
      overlay: false,

      rules: {
        required: value => !!value || '必須です',
      },
    };
  },
  mounted() {
    if (this.add) {
      this.formTitle = "部署追加";
      this.buttonTitle = "追加";
    } else {
      this.formTitle = "部署編集";
      this.buttonTitle = "保存";
    }
  },
  computed: {
    ...mapGetters(["userInfo"]),

    open: {
      get() { return this.value },
      set(val) {
        this.$emit('input', val);
      },
    },
  },
  methods: {
    close() {
      this.open = false;
    },
    async save() {
      if(
        !this.item.departmentName
      ) {
        this.info = '部署名は必須です';
        this.infoDialog = true;
        return;
      }

      //部署情報を保存
      this.overlay = true;

      const payload = {
        department: this.item,
        userId: this.userInfo.userId,
      };

      const DepartmentRepository = RepositoryFactory.get( 'departments' );
      const response = await DepartmentRepository.update(payload);
      if (response.data.result && response.data.resultCode === '0000') {
        this.$emit('ok');
        this.close();
      } else {
        this._sendError( response.data.message );
      }

      this.overlay = false;
    },
  },
  watch: {
    departmentData(val) {
      if(val) {
        _.assign(this.item, val);
      } else {
        _.assign(this.item, {
          id: 0, 
          departmentName: null, 
        });
      }

    },
    add(val) {
      if (val) {
        this.formTitle = "部署追加";
        this.buttonTitle = "追加";
      } else {
        this.formTitle = "部署編集";
        this.buttonTitle = "保存";
      }
    },
  },



}
</script>
<style lang="scss" scoped>
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"department-master-list"},[_c('v-divider'),_c('div',{staticClass:"content-area pa-8"},[_c('div',{staticClass:"text-h5 blue--text text--darken-4"},[_vm._v("部署マスタ")]),_c('v-row',{staticClass:"py-4"},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"フィルタ","single-line":"","hide-details":""},model:{value:(_vm.filterText),callback:function ($$v) {_vm.filterText=$$v},expression:"filterText"}})],1),_c('v-col',{staticClass:"d-flex justify-end",attrs:{"cols":"8"}},[_c('v-btn',{attrs:{"color":"btAccent","dark":"","depressed":""},on:{"click":function($event){return _vm.editItem(null)}}},[_c('v-icon',{staticClass:"pr-1"},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" 新規登録 ")],1)],1)],1),_c('v-data-table',{staticClass:"data-table",staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.filterText,"no-data-text":"該当データ無し","no-results-text":"該当データ無し","footer-props":{
        itemsPerPageText: '',
        itemsPerPageAllText: '全件',
        pageText: '{2}件中、{0}-{1}'

      }},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}}],null,true)})],1),_c('department-edit-dialog',{attrs:{"departmentData":_vm.editTarget,"add":_vm.editType == 'add'},on:{"ok":function($event){return _vm.fetchDepartmentData()}},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.infoDialog),callback:function ($$v) {_vm.infoDialog=$$v},expression:"infoDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v("部署を削除しますか？")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mx-2 mb-3",attrs:{"text":"","width":"190"},on:{"click":function($event){_vm.infoDialog = !_vm.infoDialog}}},[_vm._v("キャンセル ")]),_c('v-btn',{staticClass:"white--text mx-2 mb-3",attrs:{"color":"btAccent","width":"190","depressed":""},on:{"click":_vm.deleteOk}},[_vm._v("削除")]),_c('v-spacer')],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.errorInfoDialog),callback:function ($$v) {_vm.errorInfoDialog=$$v},expression:"errorInfoDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-subtitle-2"},[_vm._v(_vm._s(_vm.info))]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.errorInfoDialog = !_vm.errorInfoDialog}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }